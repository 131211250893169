<template>
    <div class="ui grid m-0 p-0">
        <div class="ui grid row p-0 m-0">
            <sidebar />
            <div class="thirteen wide column p-0">
                <navbar/>
                <!--<div class="ui stackable grid m-0">
                    <div class="row">-->
                <div class="p-1">
                    <router-view v-if="$store.state.current_user.type === 'admin' || $store.state.current_user.type === 'manager'">
                    </router-view>
                    <forbidden_page v-else />
                </div>
                    <!--</div>
                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
    import sidebar from "../components/sidebar";
    import navbar from '@/components/navbar.vue';
    import forbidden_page from '@/components/forbidden_page.vue';
    export default {
        name: "administration",
        components:{
            sidebar, navbar, forbidden_page
        }
    }
</script>

<style scoped>

</style>