<template>
    <div class="error-main">
        <h1>Oops!</h1>
        <div class="error-heading">403</div>
        <p>You do not have permission to access the document or program that you requested.</p>
    </div>
</template>

<script>
    export default {
        name: "forbidden_page"
    }
</script>

<style scoped>
    .error-main{
        text-align: center;
        margin-top: 30px;
    }
    .error-heading{
        margin: 50px auto;
        width: 250px;
        border: 5px solid #fff;
        font-size: 126px;
        line-height: 126px;
        border-radius: 30px;
        text-shadow: 6px 6px 5px #000;
    }
    .error-main h1{
        font-size: 72px;
        margin: 0;
        color: var(--main-color-blue);
        text-shadow: 0 0 5px #fff;
    }
    .error-main p{
        font-size: 18px;
        font-weight: 600;
    }

</style>